<template>
    <div class="content-main">
        <!--title区-->
        <div>
            <div class="content-header">
                <h2>卡组列表</h2>
            </div>
            <div>
                <el-divider></el-divider>
            </div>
        </div>
        <div class="content-title">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item >卡组列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div>
                如果提交的套牌配方内容不完整（如卡牌数量不足），则无法发布。 如果你中了奖，而卡组配方没有发布，可能有问题，所以请联系Card Fight!! Vanguard Card Game 秘书处。
            </div>
        </div>
        <!--列表区-->
        <div class="content-list">
            <div>
                <el-divider>推荐卡组列表</el-divider>
            </div>
            <div class="list-area">
                <div class="list-item">
                    <div v-for="i in 5" :key="i">
                        <el-row>
                            <el-col :span="5"><div class="list-title-date">2022/05/02</div></el-col>
                            <el-col :span="5"><div class="list-title-tab">优胜者</div></el-col>
                            <el-col :span="14" >
                                <div>
                                    <router-link to="/index/deckDetail?id=999">
                                        2022年大万加节三人组对决“南极探险队”——Shirley Pioneer
                                    </router-link>
                                </div>
                                <div>Stoikaia, 黑暗国度, 萨满王</div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>
                    </div>
                </div>
            </div>
        </div>
        <!--分页区-->
        <div style="margin: 0 auto;text-align: center">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                pageSize:this.$webConfig.pages.pageSize,
                pageSizes:this.$webConfig.pages.pageSizes,
                currentPage:1,
                total:1000
            }
        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getList();
            },
        },
    }
</script>

<style scoped>
    .content-main{
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 20px 0;
    }
    .content-header{
        text-align: center;
    }
    .content-title{
        width: 98%;
        margin: 0 auto;
    }
    .content-title div:last-child{
        border: 1px solid #f56f6f;
        padding: 15px;
        text-align: center;
        letter-spacing: 2px;
        margin: 40px 0;
        line-height: 30px;
    }
    .content-list{
        font-size: 24px;
    }
    .list-area {
        width: 98%;
        margin: 0 auto;
        margin-top: 20px;
    }
    .list-item > div{
        width: 100%;
        font-size: 14px;
        /*border-bottom: 2px solid #eeeeee;*/
        margin: 5px 0;
    }
    .list-item a:visited{
        color: #0000ee;
    }

    .list-title-tab{
        background-color: #4cb748;
        color: white;
        text-align: center;
        line-height: 30px;
        width: 60px;
        font-size: 14px;
    }
    .list-title-date{
        line-height: 30px;
    }

</style>
